import FranchiseAddition from 'components/FranchiseAddition/FranchiseAddition'
import React from 'react'

const FranchiseAdditionPage = () => {
  return (
    <>
        <FranchiseAddition />
    </>
  )
}

export default FranchiseAdditionPage