import Communities from 'components/Communities/Communities'
import React from 'react'

const CommunitiesPage = () => {
  return (
    <>
        <Communities />
    </>
  )
}

export default CommunitiesPage