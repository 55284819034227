import React from 'react'
import AdminManagement from 'components/AdminManagement/AdminManagement'

const AdminManagementPage = () => {
  return (
    <>
        <AdminManagement />
    </>
  )
}

export default AdminManagementPage